export default defineNuxtRouteMiddleware(async (to) => {
  const config = useRuntimeConfig()
  const { $localePath, $i18n } = useNuxtApp()
  const { countryLocalePath, countryByPathname, country, globalPath } = useCountry()

  // If its a test run we do not need to call api /auth. Maybe later if we want to test logged in pages but then we need a test user
  if (config.environment === 'test')
    return

  if (to.path === $localePath(globalPath(to.path)))
    return navigateTo({ path: countryLocalePath(globalPath(to.path)), query: to.query })

  const userStore = useUserStore()
  const gtm = useGtm()

  const allLocalizations = (path: string) => [countryLocalePath(path), $localePath(path), path]
  const isLogin = (path: string) => allLocalizations('/login').includes(path)
  const isRegister = (path: string) => allLocalizations('/register').includes(path)
  const isHome = (path: string) => ['/home', '/'].flatMap(allLocalizations).includes(path) || path === ''
  const isInternal = (path: string) => allLocalizations('/internal').includes(path)
  const isInvalidLanguageRedirect = (path: string) => /^\/[a-zA-Z]{2}\/?$/.test(globalPath(path))

  const response = await userStore.load()
  if (!response) {
    // 401 comes from shop api if user is not logged in
    // Redirects the browser on /home to wix landingpage if user is not logged in
    if (globalPath(to.path) === '/home') {
      switch ($i18n.locale.value) {
        case 'fr':
          return navigateTo('https://home.vetmazing.com', { external: true })
        default:
          return navigateTo('https://home.heiland.com', { external: true })
      }
    }

    if (!isLogin(to.path) && !isRegister(to.path)) {
      const from = globalPath(to.path)
      const loginUrl = from.length <= 1 ? '/login' : `/login?from=${countryLocalePath(from)}`
      return navigateTo({ path: countryLocalePath(loginUrl), query: to.query })
    }
    if (countryLocalePath(globalPath(to.path)) !== to.path) {
      return navigateTo({ path: countryLocalePath(globalPath(to.path)), query: to.query })
    }
    // throw createError({ statusCode: 401, statusMessage: 'Sie sind nicht eingeloggt. Bitte melden Sie sich an. Sie werden automatisch zum Login weitergeleitet...' })
  }
  else {
    gtm?.trackEvent({ customer_handle: response.handle })
    if (response.email.includes('@heiland.com')) {
      gtm?.trackEvent({
        event: 'traffic_type',
        traffic_type: 'internal',
      })
    }
    if (country.value.toLowerCase() !== countryByPathname.value.toLowerCase()
      && to.path !== countryLocalePath(globalPath(to.path))) {
      // In case someone entered an unknown language we end up here, and we're going to remove it from the path...
      if (/\/([a-zA-Z]{2}\/){3}.+/.test(to.path))
        return navigateTo({ path: to.path.replace(/(\/..\/)..\/(.*)/, '$1$2'), query: to.query })
      else
        return navigateTo({ path: countryLocalePath(globalPath(to.path)), query: to.query })
    }
    // If page is root, login or register and the user is already logged in redirect him to /order-history
    // If page is internal and the user is not a heiland user redirect him to  /order-history
    if (
      isHome(to.path)
      || isLogin(to.path)
      || isRegister(to.path)
      || isInvalidLanguageRedirect(to.path)
      || (isInternal(to.path) && !response.email.includes('@heiland.com'))) {
      // redirect user to start page if already logged in
      return navigateTo({ path: countryLocalePath('/welcome'), query: to.query })
    }
  }
})
